import React from 'react'
import { css } from '@emotion/react'

const Preview = props => {

	const {coverImg, title, imgSrc} = props;

	if (!coverImg) return null

	const previewStyle = [
		css`
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		/* box-shadow: rgba(251, 114, 104, 0.4) -5px 5px, rgba(251, 114, 104, 0.3) -10px 10px, rgba(251, 114, 104, 0.2) -15px 15px, rgba(251, 114, 104, 0.1) -20px 20px, rgba(251, 114, 104, 0.05) -25px 25px; */

		+ * {
			margin-top: 2rem;
		}
		`
	]


	return (
		<div className="preview" css={[
			previewStyle
		]}>



			{(coverImg && imgSrc) &&
				<a href={imgSrc}>
				{coverImg}
				</a>
			}

			{
				title &&

				<h4 className="hidden">{title}</h4>

			}
		</div>
	)
}
//
// Preview.defaultProps = {
// 	data: GalleryData,
// }

export default Preview
