import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Image1 from '../images/deb/IMG_1256.jpg'
import Image2 from '../images/deb/IMG_1254.jpg'
import Image3 from '../images/deb/IMG_1257_opti.jpg'
import Image4 from '../images/deb/IMG_1258.jpg'
import Image5 from '../images/deb/IMG_1259.jpg'
import Image6 from '../images/deb/IMG_1260.jpg'
import Image7 from '../images/deb/IMG_1261.jpg'
import Image8 from '../images/deb/IMG_1262.jpg'

const GalleryData = [
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1256.JPEG"
          aspectRatio={1}
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image1,
	},
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1254.JPEG"
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image2,
	},
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1257_opti.jpg"
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image3,
	},
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1258.JPEG"
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image4,
	},
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1259.JPEG"
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image5,
	},
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1260.JPEG"
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image6,
	},
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1261.JPEG"
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image7,
	},
	{
		name: 'Deb Braun',
		image: <StaticImage
          src="../images/deb/IMG_1262.JPEG"
          alt="Deb Braun"
		  layout={`fullWidth`}
        />,
		imageSrc: Image8,
	},
]

export default GalleryData
