import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/Container"
import PageHeader from "../components/PageHeader"
import Section from '../components/Section'
import Gallery from '../components/Gallery'
import Preview from '../components/Preview'
import GalleryData from '../data/gallery-data'
import Button from '../components/Button'
import MediaPlayer from '../components/MediaPlayer'

const IndexPage = () => {

    return (
      <Layout isHomepage>
        <Seo title="Home" />

        <Container size="small">

        <StaticImage
          src="../images/deb/IMG_1255_opti.jpg"
          aspectRatio={1}
          alt="Deb Braun"
          className="shadow-xl"
        />
        <section id="about">
        <PageHeader
        className="text-center mt-10 max-w-xl mx-auto"
        header={`
            In Loving Memory of <span class="text-primary-default block">Deb Braun</span>
            `}
        as="h1"
        preheader="August 26, 1966 – October 29, 2021"

        />

        <div className="text-xl leading-relaxed text-center font-normal font-brand">

            <p>
            <em>
                We were blessed with the world’s most magical wife & mom. She would make us smile from the moment we woke up to to moment we went to bed; with our morning cuddles, family dinners & all the silly marble games in-between. She had the power to make any room light up wherever she went with her sparkle; day or night. A love truly limitless.
            </em>
            </p>
            <p className="mb-20 mt-0" style={{fontSize:'1.65rem'}}>
            <strong>– Mike, Madison, & Devan</strong>
            </p>
        </div>

        </section>

        </Container>

        <section className="pb-10">
            <Container size="medium">
            <div>
            <MediaPlayer source={`https://chasek23mywebsitebucket.s3.amazonaws.com/IMG_1268.MOV.mp4`}>
                <StaticImage
                    src={`../images/videos/db_videooo.jpg`}
                    alt="video preview"
                    style={{opacity: '0.65'}}
                    />
            </MediaPlayer>
            </div>
            </Container>

        </section>

        <section id="lullaby" className="hidden">
            <Container size="medium">

            <div className="mt-20 bg-secondary-lighter p-8 md:p-12 lg:p-20 lg:pb-20 md:pb-12 pb-12">
                <div className="max-w-lg mx-auto">
                <h2
                dangerouslySetInnerHTML={{__html: "Deb’s lullaby<br/> to her girls"}}
                className="text-4xl italic mb-10"
                style={{lineHeight: '100%'}}
                />
                <p>
                    <em>Won’t you come over to my house,<br/> won’t you come over and play.</em>
                </p>
                <p>
                    <em>I’ve got a dolly a play thing or two.</em>
                </p>
                <p>
                    <em>I live in the house down the way,<br/> I’ll give you candy and sweet things,<br/> I’ll put your hair in a curl.</em>
                </p>
                <p>
                    <em>Won’t you come over to my house & play<br/> and be my little girl.</em>
                </p>
                </div>
            </div>



            </Container>

        </section>
        <Section id="photos" size="small" className="mt-20">

        <Section.Column className="w-full">
        <Gallery galleryMode={true}>

        {
            GalleryData.map((img,k) => {
                return (
                    <Preview
                    coverImg={img.image}
                    title={img.name}
                    imgSrc={img.imageSrc}
                    key={k}
                    />
                )
            })
        }

        </Gallery>

        </Section.Column>

        </Section>

        <Container size="medium">
        <div className="mt-20 bg-secondary-lighter p-8 md:p-12 lg:p-20 lg:pb-20 md:pb-12 pb-12">
        <Section id="give" size="large" gapSize="large">
            <Section.Column className="md:flex-8/12 md:max-w-8/12"  gapSize="large">
                <h2
                dangerouslySetInnerHTML={{__html: "In lieu of flowers"}}
                className="text-5xl md:text-6xl lg:text-7xl"
                />
                <p className="leading-normal">
                The Braun family welcomes contributions to Sources Charity in honour of Deb as their mission established a sense of community that beamed love, joy and support dear to her heart over the years.
                </p>
            </Section.Column>
            <Section.Column className="flex-trueauto md:mt-auto md:mb-auto md:text-right"  gapSize="large">

                <Button variation="outline" type="external" href="https://www.canadahelps.org/en/pages/in-loving-memory-10/" size="large">Give Now</Button>
            </Section.Column>
        </Section>
        </div>
        </Container>
      </Layout>
    )
}

export default IndexPage
