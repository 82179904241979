import React from 'react'
import PropTypes from 'prop-types'
import Container from './Container'

const Column = props => {

	const {gapSize, className, children, ...rest} = props;

	let gapOffset = 'px-6'

	if (gapSize === 'tiny') {
		gapOffset = 'px-2'
	}

	if (gapSize === 'small') {
		gapOffset = 'px-3'
	}

	if (gapSize === 'large') {
		gapOffset = 'px-10'
	}

	if (gapSize === 'huge') {
		gapOffset = 'px-16'
	}

	return (
		<div {...rest} className={`w-full ${className} md:${gapOffset}`}>
			{children}
		</div>
	)
}

Column.propTypes = {
	children: PropTypes.node.isRequired,
	gapSize: PropTypes.string,
	className: PropTypes.string,
}

Column.defaultProps = {
	gapSize: ``,
	className: `md:flex-1/2 md:max-w-1/2 md:w-auto md:pt-0 pt-5`,
}

export class Section extends React.Component {
	static Column = Column;

	render() {

		const {className, containerClassName, gapSize, size, children, ...rest} = this.props;

		let gapOffset = '-mx-6'

		if (gapSize === 'tiny') {
			gapOffset = '-mx-2'
		}

		if (gapSize === 'small') {
			gapOffset = '-mx-3'
		}

		if (gapSize === 'large') {
			gapOffset = '-mx-10'
		}

		if (gapSize === 'huge') {
			gapOffset = '-mx-16'
		}

		return (
			<div {...rest}>
			<Container size={size} className={containerClassName}>
				<div className={`flex flex-row justify-center md:flex-nowrap flex-wrap md:${gapOffset} ${className}`}>


						{children}

				</div>
			</Container>
			</div>
		)
	}
}

Section.propTypes = {
	children: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([Column])
    })
	),
	size: PropTypes.string,
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	gapSize: PropTypes.string,
}

Section.defaultProps = {
	size: `medium`,
	className: ``,
	gapSize: `medium`,
	containerClassName: ``,
}

export default Section
