import React from 'react'

const PageHeader = props => {

	const {as:Component, header,preheader,subheader,content,children,...rest} = props;

	if (!header) return null

	return (

		<div {...rest}>
			<hgroup>
				{preheader &&
					<span
					className="text-sm font-sans font-bold text-gray-400"
					dangerouslySetInnerHTML={{__html: preheader}}
					/>
				}
				<Component className="text-7xl md:text-8xl lg:text-9xl xl:text-10xl"
				dangerouslySetInnerHTML={{__html: header}}
				/>
				{subheader &&
					<h3
					className="text-3xl text-black -mt-4"
					dangerouslySetInnerHTML={{__html: subheader}}
					/>
				}

				{content &&
					<p
					className="text-gray-600 text-sm -mt-5"
					dangerouslySetInnerHTML={{__html: content}}
					/ >
				}

				{children && children}


			</hgroup>

		</div>
	)
}

PageHeader.defaultProps = {
	as: 'h1',
}

export default PageHeader
