import React, { Component } from 'react'
import { withMediaProps } from 'react-media-player'
import SimpleLineIcon from 'react-simple-line-icons';
import { StaticImage } from 'gatsby-plugin-image'
// import { css } from '@emotion/react'

class MediaControls extends Component {
  shouldComponentUpdate({ media }) {
    return this.props.media.isPlaying !== media.isPlaying
  }

  _handlePlayPause = () => {
    this.props.media.playPause()
  }

  // _handleFirstPlay = () => {
  //   this.props.media.play()
  // }



  render() {
    const { style, media, children } = this.props

    const imgStyle = {
      opacity: '0.65',
    }




    return (
      <>
      {(media.currentTime === 0 && !media.isPlaying) &&
        <div className="absolute top-0 right-0 left-0 bottom-0 w-full z-50 transition-all bg-black">
          {children ? (
            children
          ) : (
            <StaticImage
            src={`../images/videos/db_videooo.jpg`}
            alt="video preview"
            style={imgStyle}
            />
          )}
        </div>
      }
      <button
        type="button"
        className={`p-12 bg-secondary-default h-24 w-24 rounded-full flex justify-center items-center duration-500 absolute top-0 bottom-0 right-0 left-0 m-auto z-50 ${media.isPlaying ? 'opacity-0 hover:opacity-100' : 'opacity-80 hover:opacity-100'}`}
        style={style}
        onClick={this._handlePlayPause}
      >

      <SimpleLineIcon size="large" style={{fontSize: '36px'}} color="black" name={media.isPlaying ? "control-pause" : "control-play" } />
      </button>
      </>
    )
  }
}

export default withMediaProps(MediaControls)
