import React from 'react'
import PropTypes from 'prop-types'
import * as colStyles from '../styles/columns.module.scss'
import { SRLWrapper } from "simple-react-lightbox";

const Gallery = ( {galleryMode, children, ...rest} ) => {

	const imageOptions = {
	  settings: {
	    overlayColor: "rgba(0,0,0,0.8)",
      disablePanzoom: true,
      disableWheelControls: true,
      disableKeyboardControls: false,
      autoplaySpeed: 0,
	  },
	  thumbnails: {
		  showThumbnails: false,
	  },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
	  backgroundColor: 'rgba(30,30,36,0.0)',

    },
    caption: {
    captionColor: "#ffffff",
    captionFontWeight: '600',
    captionContainerPadding: '0px 0px 40px 0px',
    captionFontSize: '0.875rem',
	showCaption: false,
  }
	};

	if (galleryMode === true) {
		return (

			<SRLWrapper options={imageOptions}>
			<div className={colStyles.columnWrap} {...rest}>
				{children}
			</div>
			</SRLWrapper>

		)
	}
	return (


		<div className={colStyles.columnWrap} {...rest}>
		{children}
		</div>
	)
}

Gallery.propTypes = {
	children: PropTypes.node.isRequired,
	galleryMode: PropTypes.bool,
}

Gallery.defaultProps = {
	galleryMode: false,
}

export default Gallery
