import React from 'react'
import { Media, Player } from 'react-media-player'
import MediaControls from './MediaControls'
// const { PlayPause, MuteUnmute } = controls


const MediaPlayer = props => {

	const { source, children } = props;

	if (!source) return null

	return (
	<Media>
        <div className="media mediaPlayer relative group">
          <Player src={source} />
          <MediaControls />

          {children ? (
                <MediaControls>
                {children}
                </MediaControls>
          ) : (
               <MediaControls />
          )
    }
        </div>
      </Media>
	)
}


export default MediaPlayer
